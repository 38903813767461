import React from 'react';

export default function FooterControl() {
    return (
        <footer>
            <div className="px-4 py-4 bg-gray-600 text-white flex justify-center">
                <span className="font-bold">Evola Deliveries</span>&nbsp;is a Eve Online Corporation
            </div>
        </footer>
    );

}